
.home-page {
    display: flex;
    justify-content: space-between;

    .com-title {
        height: 45px;
        line-height: 45px;
        border-bottom: 2px solid #dedede;
        display: flex;
        align-items: center;
        justify-content: space-between;
        div {
            display: flex;
            align-items: center;

            img {
                width: 24px;
                height: 24px;
                margin-right: 11px;
                margin-left: 3px;
            }
            h5 {
                height: 45px;
                line-height: 45px;
                font-size: 24px;
                color: #000000;
                border-bottom: 3px solid #bd1a2d;
                a {
                    color: #000000;
                    font-size: 24px;
                    line-height: 51px;
                    display: block;
                }
            }
        }

        ul {
            display: flex;
            li {
                font-size: 18px;
                margin-right: 18px;
                cursor: pointer;
                line-height: 36px;
                color: #333;
            }
        }
        span {
            font-size: 14px;
            color: #404040;
            cursor: pointer;
            a {
                font-size: 14px;
                color: #404040;
            }
            img {
                width: 4px;
                height: 8px;
                margin-left: 10px;
            }
        }
    }
    .com-right {
        padding: 0 20px;
        width: 316px;
        box-sizing: border-box;
        background: #fefefe;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
        margin: 24px 0;
    }
    .com-p {
        width: 276px;
        line-height: 17px;
        margin: 0 auto 18px;
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-left: 10px;
        display: inline-block;
        position: relative;
        img {
            width: 19px;
            height: 19px;
            margin-right: 4px;

            vertical-align: middle;
        }
    }
    .com-p::before {
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #ffac13;
        position: absolute;
        left: 0;
        top: 8px;
    }
    .left-part {
        .banner-wrap {
            width: 860px;
            height: 357px;
            box-sizing: border-box;
            // box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            margin-top: 24px;
        }
        .type-cont-lt .type-iterm {
            position: relative;
            display: inline-block;
            width: 170px;
            height: 357px;
            background: #fff;
            border: 1px solid #e5e5e5;
            box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
            margin-right: 24px;
        }
        .type-cont-lt .type-iterm > li {
            font-size: 18px;
            color: #2e2e2e;

            text-align: center;
            // padding: 0 15px;
            width: 170px;
            box-sizing: border-box;
        }
        .type-cont-lt .type-iterm > li:first-child {
            height: 46px;
            line-height: 46px;
            padding: 0 15px;
            margin-bottom: 6px;
            div {
                height: 46px;

                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 2px solid #ddd;
                img {
                    width: 20px;
                    height: 20px;
                }
                p {
                    font-size: 22px;
                    color: #bd192d;
                    line-height: 46px;
                    border-bottom: 3px solid #bd192d;
                }
                .more {
                    width: 8px;
                    height: 14px;
                }
            }
        }
        .type-cont-lt .type-iterm > li a.type-title {
            display: block;
            width: 140px;
            margin: 0 auto;
            line-height: 50px;
            text-align: center;
            border-bottom: 1px solid #dcdcdc;
            cursor: pointer;
        }
        .type-cont-lt .type-iterm > li:last-child {
            border: none;
            a.type-title {
                border-bottom: none;
            }
        }
        // .type-cont-lt .type-iterm > li > a:before {
        //     content: '';
        //     float: left;
        //     width: 26px;
        //     height: 26px;
        //     margin-left: 26px;
        //     margin-top: 16px;
        //     margin-right: 10px;
        // }
        .type-cont-lt .type-pop {
            position: absolute;
            top: -1px;
            left: 157px;
            z-index: 12;
            width: 692px;
            height: 357px;
            display: none;
        }
        .type-cont-lt .pop-box {
            width: 100%;
            box-sizing: border-box;
            margin-left: 10px;
            border: 1px solid #e5e5e5;
            background: #fff;
            height: 357px;
            overflow-y: auto;
        }
        .type-iterm li:hover .type-pop {
            display: block;
        }
        .type-pop .type-name {
            position: relative;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            padding: 8px 10px 0px 40px;
            border-bottom: 1px solid #f5f5f5;
        }
        .type-pop .type-name a {
            cursor: pointer;
        }
        .type-pop .type-name span {
            position: absolute;
            top: 8px;
            left: 10px;
            z-index: 4;
            font-weight: 600;
        }
        .type-pop .type-name a {
            display: inline-block;
            margin: 0 16px 8px 0;
        }
        // .type-iterm > li:nth-child(1) > a:before {
        //     background: url('../assets/img/icon_nav_zjzx.png') no-repeat;
        //     background-size: 100% 100%;
        // }
        // .type-iterm > li:nth-child(2) a:before {
        //     background: url('../assets/img/icon_nav_jjgl.png') no-repeat;
        //     background-size: 100% 100%;
        // }
        // .type-iterm > li:nth-child(3) > a:before {
        //     background: url('../assets/img/icon_nav_shzf.png') no-repeat;
        //     background-size: 100% 100%;
        // }
        // .type-iterm > li:nth-child(4) > a:before {
        //     background: url('../assets/img/icon_nav_whcm.png') no-repeat;
        //     background-size: 100% 100%;
        // }
        // .type-iterm > li:nth-child(5) > a:before {
        //     background: url('../assets/img/icon_nav_lsdl.png') no-repeat;
        //     background-size: 100% 100%;
        // }
        // .type-iterm > li:nth-child(6) > a:before {
        //     background: url('../assets/img/icon_nav_yywx.png') no-repeat;
        //     background-size: 100% 100%;
        // }

        .library-list {
            width: 860px;
            height: 364px;
            box-sizing: border-box;
            padding: 7px 14px 0;
            background: #fefefe;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            margin: 24px 0;
            .com-title {
                div ul li {
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                    // max-width: 100px;
                    // white-space: nowrap;
                    a {
                        color: #333;
                    }
                }
            }

            .library-content {
                margin-top: 10px;
                .library-title-ul {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;
                    li {
                        width: 158px;
                        height: 36px;
                        line-height: 36px;
                        background: #f0f0f0;
                        color: #616161;
                        text-align: center;
                        font-size: 16px;
                        cursor: pointer;
                    }

                    li:hover {
                        background: #bd1a2d;
                        color: #fff;
                    }
                    .active-library {
                        background: #bd1a2d;
                        color: #fff;
                    }
                    .active {
                        background: #bd1a2d;
                        color: #fff;
                    }
                }
                .content-wrap {
                    height: 220px;
                    background: url(../assets/img/border.png);
                    background-size: 1px 50%;
                    background-repeat: repeat-y;
                    background-position: 50% 0%;

                    ul.academic {
                        width: 100%;

                        li {
                            width: 414px;
                            box-sizing: border-box;
                            line-height: 19px;
                            // padding-bottom: 20px;
                            padding-bottom: 18px;
                            padding-top: 2px;
                            font-size: 16px;

                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            cursor: pointer;
                        }

                        li {
                            padding-left: 10px;
                            position: relative;
                        }
                        li::before {
                            content: '';
                            width: 4px;
                            height: 4px;
                            border-radius: 50%;
                            background: #ffac13;
                            position: absolute;
                            left: 0;
                            top: 8px;
                        }

                        li:nth-child(2n + 2) {
                        }
                        li:nth-child(2n + 1) {
                            padding-left: 31px;
                        }
                        li:nth-child(2n + 1)::before {
                            left: 21px;
                        }
                        li:first-child::before {
                            display: none;
                        }
                        li:first-child {
                            padding-left: 0px;
                            padding-bottom: 1px;
                            padding-left: 0;
                            overflow: auto;
                            white-space: inherit;

                            img {
                                width: 116px;
                                height: 70px;
                                margin-bottom: 3px;
                                cursor: pointer;
                                box-shadow: 0px 2px 6px 0px rgba(15, 11, 5, 0.29);
                                // height: 70px;
                                // margin-bottom: 3px;
                            }
                            div {
                                padding-left: 15px;
                                h5 {
                                    font-size: 16px;
                                    margin-bottom: 11px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    padding-left: 10px;
                                    position: relative;
                                }
                                h5::before {
                                    content: '';
                                    width: 4px;
                                    height: 4px;
                                    border-radius: 50%;
                                    background: #ffac13;
                                    position: absolute;
                                    left: 0;
                                    top: 8px;
                                }
                                p {
                                    color: #656565;
                                    line-height: 21px;
                                    font-size: 14px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2; // 控制多行的行数
                                    -webkit-box-orient: vertical;
                                }
                            }
                        }

                        li:nth-child(2) {
                            padding-left: 31px;
                        }
                        li:nth-child(2)::before {
                            left: 21px;
                        }
                    }
                    ul.academic {
                        width: 100%;
                        li:first-child {
                            div {
                                h5 {
                                    margin-bottom: 8px;
                                }
                            }
                        }
                    }
                    .learner-ins {
                        .learner-ins-ul {
                            display: flex;
                            justify-content: space-between;
                            li {
                                width: 414px;
                                display: flex;
                                box-sizing: border-box;
                                padding-right: 22px;
                                img {
                                    width: 116px;
                                    height: 150px;
                                    margin-right: 15px;
                                    cursor: pointer;
                                    box-shadow: 0px 2px 6px 0px rgba(15, 11, 5, 0.29);
                                }
                                div.right-learner {
                                    flex: 1;
                                    h5 {
                                        width: 246px;
                                        font-size: 18px;
                                        margin-bottom: 16px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        cursor: pointer;
                                    }
                                    p {
                                        color: #656565;
                                        font-size: 14px;
                                        line-height: 24px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 4; // 控制多行的行数
                                        -webkit-box-orient: vertical;
                                    }
                                }
                            }
                            li:nth-child(2) {
                                padding-left: 18px;
                            }
                        }
                        .bot-learner-ins-ul {
                            // margin-top: 14px;
                            display: flex;
                            li {
                                display: flex;
                                flex-wrap: wrap;
                                box-sizing: border-box;
                                padding: 0 20px;
                                justify-content: space-between;
                                p {
                                    line-height: 30px;
                                    font-size: 16px;
                                    box-sizing: border-box;
                                    padding-left: 10px;
                                    position: relative;
                                    cursor: pointer;
                                    width: 125px;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                }
                                p::before {
                                    content: '';
                                    position: absolute;
                                    left: 0;
                                    top: 13px;
                                    width: 4px;
                                    height: 4px;
                                    border-radius: 50%;
                                    background: #ffab13;
                                }
                            }
                            .left-expert {
                                width: 430px;
                                padding-left: 0;

                                p {
                                    min-width: 125px;
                                }
                            }
                            .right-ins {
                                width: 430px;
                                // p:nth-child(2n + 1) {
                                //     width: 245px;
                                // }
                                // p:nth-child(2n + 2) {
                                //     width: 130px;
                                // }
                            }
                            .expert-sty {
                                p {
                                    width: 125px;
                                }
                            }
                            .ins-sty {
                                p {
                                    width: 187px;
                                }
                            }
                            .collected-sty {
                                p {
                                    width: 187px;
                                }
                            }
                            .wenji-sty {
                                p {
                                    width: 187px;
                                }
                            }
                        }
                    }
                    .chart-img {
                        display: flex;
                        div {
                            width: 415px;
                            box-sizing: border-box;
                            position: relative;
                            img {
                                // width: 257px;
                                // // 350px;
                                // height: 150px;
                                // // 204px;
                                width: 360px;
                                height: 170px;
                                display: block;
                                cursor: pointer;
                            }
                            p {
                                position: absolute;
                                left: 0;
                                top: 130px;
                                width: 360px;
                                font-size: 14px;
                                padding-left: 10px;
                                line-height: 40px;
                                color: #fff;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                background: rgba(2, 2, 2, 0.5);
                                cursor: pointer;
                                // background: rgba(238, 234, 239, 0.2);
                            }
                            h6 {
                                font-size: 16px;
                                line-height: 40px;
                                width: 360px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                cursor: pointer;
                                padding-left: 10px;
                                position: relative;
                                margin-top: 5px;
                            }
                            h6::before {
                                position: absolute;
                                left: 0;
                                top: 19px;
                                content: '';
                                width: 4px;
                                height: 4px;
                                border-radius: 50%;
                                background: #ffac13;
                            }
                        }
                        div:first-child {
                        }
                        div:last-child {
                            padding-left: 50px;
                            p {
                                left: 50px;
                            }
                        }
                    }
                    // 专题
                    .special {
                        background: #fff;
                        width: 100%;
                        min-height: 211px;
                        .top-special {
                            display: flex;
                            .img-wrap {
                                margin-right: 13px;
                                margin-bottom: 17px;
                                img {
                                    width: 120px;
                                    height: 81px;
                                    cursor: pointer;
                                }
                            }
                            .special-content {
                                h5 {
                                    color: #333;
                                    font-size: 18px;
                                    margin-bottom: 19px;
                                    cursor: pointer;
                                }
                                p {
                                    color: #656565;
                                    font-size: 14px;
                                    text-overflow: -o-ellipsis-lastline;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                }
                            }
                        }
                        .bot-special {
                            display: flex;
                            justify-content: space-between;
                            flex-wrap: wrap;
                            p {
                                width: 414px;
                                padding-left: 10px;
                                font-size: 16px;
                                position: relative;
                                line-height: 30px;
                                cursor: pointer;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                            p:nth-child(2n+1) {
                                border-right: 1px solid #ebebeb;
                            }
                            p:nth-child(2n+2) {
                                padding-left: 31px;
                            }
                            p:nth-child(2n+1)::before {
                                 left: 0;
                             }
                            p:nth-child(2n+2)::before {
                                 left: 19px;
                             }
                                
                            p::before {
                                content: '';
                                position: absolute;
                                top: 15px;
                                // left: 0;
                                width: 4px;
                                height: 4px;
                                display: block;
                                border-radius: 50%;
                                background: #ffab13;
                            }
                        }
                    }
                }
            }
        }
        .excellent-collection {
            width: 860px;
            // height: 364px;
            box-sizing: border-box;
            padding: 7px 14px 0;
            background: #fefefe;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            margin: 24px 0;
            .iedolTab {
                position: relative;
            }
            .iedolTab .lookmore {
                position: absolute;
                bottom: 6px;
                right: -1px;
                z-index: 1;
                color: #bd1a2d;
            }
            .fineIedol {
                position: relative;
                margin-top: 34px;
                margin-bottom: 50px;
                z-index: 0;
            }
            .fineIedol::before {
                position: absolute;
                bottom: 13px;
                left: 0;
                content: '';
                z-index: -1;
                width: 94.5%;
                height: 1px;
                background: #bd1a2d;
            }
            .fineIedol li:first-child {
                margin-left: 120px;
            }
            .fineIedol li {
                display: inline-block;
                width: 50px;
                text-align: center;
                margin: 0 50px;
            }
            .fineIedol li p {
                padding-bottom: 6px;
                position: relative;
            }

            .fineIedol .ticon {
                position: relative;
                width: 28px;
                height: 28px;
                margin: 0 auto;
                cursor: pointer;
                background: #fff;
                border: 1px solid #bd1a2d;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
                box-sizing: border-box;
                /*background: url('../assets/img/u662.png') no-repeat;*/
            }
            .fineIedol .ticon i {
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: -9px;
                margin-top: -9px;
                width: 18px;
                height: 18px;
                cursor: pointer;
                background: #bd1a2d;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
            }
            .fineIedol .ticon.active {
                // position: absolute;
                // top: -6px;
                // width: 84px;
                // height: 84px;

                position: absolute;
                top: 1px;
                width: 74px;
                height: 74px;
            }
            .fineIedol .ticon.active i {
                // width: 70px;
                // height: 70px;
                // margin-left: -35px;
                // margin-top: -35px;
                // font-style: normal;
                // font-size: 26px;
                // line-height: 70px;
                // text-align: center;
                // color: #fff;

                width: 60px;
                height: 60px;
                margin-left: -30px;
                margin-top: -30px;
                font-style: normal;
                font-size: 24px;
                line-height: 63px;
                text-align: center;
                color: #fff;
            }
            // .swiper-button-prev, .swiper-button-next{
            //     position:absolute;
            //     z-index:999;
            //     top:50%;
            //     left:-10px;
            //     margin-top:-30px;
            //     width: 60px;
            //     height: 60px;
            //     background:url(../assets/img/icon_torr_left.png);
            //     background-size: 100% 100%;
            //     /*background: #ff4652;*/
            //     cursor:pointer;
            //     /*display:none;*/
            // }
            // .swiper-button-next{
            //     background:url(../assets/img/icon_torr_right.png);
            //     background-size: 100% 100%;
            //     /*background-position:0 -60px;*/
            //     left:auto;
            //     right:-10px;
            // }
            .swiper-book .card-carousel-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 20px 0 40px;
                color: #666a73;
            }

            .swiper-book .card-carousel {
                display: flex;
                justify-content: center;
                // width: 958px;
                width: 708px;
            }
            .swiper-book .card-carousel--overflow-container {
                overflow: hidden;
            }
            .swiper-book .card-carousel--nav__left,
            .card-carousel--nav__right {
                display: inline-block;
                width: 30px;
                height: 30px;
                padding: 10px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                border-top: 4px solid #bd1a2d;
                border-right: 4px solid #bd1a2d;
                cursor: pointer;
                margin: 0px 12px;
                transition: transform 150ms linear;
            }
            .swiper-book .card-carousel--nav__left[disabled],
            .card-carousel--nav__right[disabled] {
                opacity: 0.2;
                border-color: black;
            }
            .swiper-book .card-carousel--nav__left {
                transform: rotate(-135deg);
            }
            .swiper-book .card-carousel--nav__left:active {
                transform: rotate(-135deg) scale(0.9);
            }
            .swiper-book .card-carousel--nav__right {
                transform: rotate(45deg);
            }
            .swiper-book .card-carousel--nav__right:active {
                transform: rotate(45deg) scale(0.9);
            }

            .swiper-book .card-carousel-cards {
                display: flex;
                transition: transform 150ms ease-out;
                transform: translatex(0px);
            }
            .swiper-book .card-carousel-cards .card-carousel--card {
                margin: 0 16px;
                cursor: pointer;
                background-color: #fff;
                z-index: 3;
                margin-bottom: 2px;
                width: 153px;
                height: 222px;
            }
            .swiper-book .card-carousel-cards .card-carousel--card:first-child {
                margin-left: 0;
            }
            .swiper-book .card-carousel-cards .card-carousel--card:last-child {
                margin-right: 0;
            }
            .swiper-book .card-carousel-cards .card-carousel--card img {
                vertical-align: bottom;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                // border-radius: 4px;
                transition: opacity 150ms linear;
                user-select: none;
                // width: 153px;
                // border: 1px  solid rgba(0, 0, 0, 0.1);
                width: 153px;
                height: 222px;
                box-sizing: border-box;
                border: 1px solid rgba(0, 0, 0, 0.1);
                box-shadow: 0px 0px 6px 1px rgba(15, 11, 5, 0.2);
            }
            .swiper-book .card-carousel-cards .card-carousel--card img:hover {
                opacity: 0.5;
            }
            .swiper-book .card-carousel-cards .card-carousel--card--footer {
                border-top: 0;
                padding: 7px 15px;
            }
            .swiper-book .card-carousel-cards .card-carousel--card--footer p {
                padding: 3px 0;
                margin: 0;
                margin-bottom: 2px;
                font-size: 19px;
                font-weight: 500;
                color: #2c3e50;
                user-select: none;
            }
            .swiper-book .card-carousel-cards .card-carousel--card--footer p:nth-of-type(2) {
                font-size: 12px;
                font-weight: 300;
                padding: 6px;
                background: rgba(40, 44, 53, 0.06);
                display: inline-block;
                position: relative;
                margin-left: 4px;
                color: #666a73;
            }
            .swiper-book .card-carousel-cards .card-carousel--card--footer p:nth-of-type(2):before {
                content: '';
                float: left;
                position: absolute;
                top: 0;
                left: -12px;
                width: 0;
                height: 0;
                border-color: transparent rgba(40, 44, 53, 0.06) transparent transparent;
                border-style: solid;
                border-width: 12px 12px 12px 0;
            }
            .swiper-book .card-carousel-cards .card-carousel--card--footer p:nth-of-type(2):after {
                content: '';
                position: absolute;
                top: 10px;
                left: -1px;
                float: left;
                width: 4px;
                height: 4px;
                border-radius: 2px;
                background: white;
                box-shadow: -0px -0px 0px #004977;
            }
        }
    }
    .right-part {
        .new-resource-wrap {
            // padding: 0 20px;
            // width: 316px;
            height: 357px;
            // box-sizing: border-box;
            // background: #fefefe;
            // border: 1px solid #f5f5f5;
            // box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            // margin: 24px 0;
            .com-title {
                div {
                    img.title-img {
                        width: 28px;
                        height: 15px;
                    }
                }
            }
            .new-resource {
                ul {
                    display: flex;
                    justify-content: space-between;
                    width: 276px;
                    margin: 14px auto 20px;
                    li {
                        width: 85px;
                        height: 36px;
                        cursor: pointer;
                        font-size: 16px;
                        background: #efefef;
                        text-align: center;
                        line-height: 36px;
                    }
                    // li:hover {
                    //     background: #bd192d;
                    //     color: #fff;
                    // }
                    .active-resource {
                        background: #bd192d;
                        color: #fff;
                    }
                }
                .new-resource-list {
                    p {
                        cursor: pointer;
                    }
                    .new-resource-book {
                        display: flex;
                        justify-content: space-between;
                        img {
                            width: 100px;
                            height: 140px;
                            margin-bottom: 22px;
                            box-shadow: 0px 2px 6px 0px rgba(15, 11, 5, 0.29);
                            cursor: pointer;
                        }
                        div {
                            width: 160px;
                            h6 {
                                width: 160px;
                                font-size: 16px;
                                margin-bottom: 10px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                cursor: pointer;
                            }
                            p {
                                line-height: 22px;
                                font-size: 14px;
                                color: #666;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 5;
                                -webkit-box-orient: vertical;
                            }
                        }
                    }
                }
            }
        }
        .new-dynamic-wrap {
            // padding: 0 20px;
            // width: 316px;
            height: 365px;
            // box-sizing: border-box;
            // background: #fefefe;
            // border: 1px solid #f5f5f5;
            // box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            // margin: 24px 0;
            .new-dynamic {
                padding-top: 20px;
                p {
                    cursor: pointer;
                }
            }
        }
        .open-access-wrap {
            // padding: 0 20px;
            // width: 316px;
            height: 365px;
            // box-sizing: border-box;
            // background: #fefefe;
            // border: 1px solid #f5f5f5;
            // box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            // margin: 24px 0;
            .com-title {
                div {
                    img.title-img {
                        width: 28px;
                        height: 15px;
                    }
                }
            }
            .open-access {
                padding-top: 20px;
                p {
                    cursor: pointer;
                }
            }
        }
        .new-preprint-wrap {
            height: 364px;
            .com-title {
                div {
                    img.title-img {
                        width: 29px;
                        height: 29px;
                    }
                }
            }
            .new-preprint {
                margin-top: 20px;
                p {
                    cursor: pointer;
                }
            }
        }
        .new-special-wrap {
            // padding: 0 20px;
            // width: 316px;
            // box-sizing: border-box;
            // background: #fefefe;
            // border: 1px solid #f5f5f5;
            // box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            // margin: 24px 0;
            .new-special {
                .top-new-special {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 15px;
                    li {
                        width: 134px;
                        height: 104px;
                        margin-bottom: 15px;
                        cursor: pointer;
                        img {
                            display: block;
                            width: 100%;
                            height: 87px;
                        }
                        p {
                            line-height: 17px;
                            height: 17px;
                            width: 134px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            background: #d1d1d1;
                            font-size: 12px;
                            text-align: center;
                        }
                    }
                }
                .com-p {
                    cursor: pointer;
                }
            }
        }
        .new-media-wrap {
            .new-media {
                margin-top: 20px;
                position: relative;
                ul {
                    display: flex;
                    justify-content: space-between;
                    li {
                        width: 127px;
                        height: 36px;
                        line-height: 36px;
                        text-align: center;
                        background: #efefef;
                        color: #000000;
                        cursor: pointer;
                    }
                    .active-new-media {
                        color: #fff;
                        background: #bd192d;
                    }
                }
                img {
                    width: 281px;
                    height: 175px;
                    margin: 13px auto 16px;
                    cursor: pointer;
                    display: block;
                }
                h6 {
                    position: absolute;
                    left: 0;
                    top: 183px;
                    width: 281px;
                    font-size: 14px;
                    line-height: 40px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: #fff;
                    background: rgba(2, 2, 2, 0.5);
                    padding-left: 10px;
                    box-sizing: border-box;
                    cursor: pointer;
                }

                p {
                    cursor: pointer;
                }
            }
        }
        .new-service-wrap {
            .new-service {
                margin-top: 15px;
                display: flex;
                flex-wrap: wrap;
                p {
                    width: 127px;
                    font-size: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding-left: 10px;
                    display: inline-block;
                    position: relative;
                    margin-bottom: 15px;
                    a {
                        color: #333;
                        font-size: 16px;
                    }
                }
                p::before {
                    content: '';
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background: #ffac13;
                    position: absolute;
                    left: 0;
                    top: 8px;
                }
            }
        }
    }
}
